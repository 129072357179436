.main {
  width: 100%;
  height: 100vh;
  flex: 1;
  text-align: center;
  padding-top: 48vh;
  background-color: #1d446add;
  position: fixed;
  z-index: 5000;
}
.bar {
  width: 100px;
  height: 100px;
  /* position: absolute; */
}
.bar > span {
  width: 10px;
  height: 10px;
  display: block;
  background-color: #fff;
  position: absolute;
  animation-name: loading;
  animation-iteration-count: infinite;
  animation-duration: 4s;
  background-color: #fba500;
  animation-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
}
.bar > span:nth-child(2) {
  animation-delay: 1s;
}
.bar > span:nth-child(3) {
  animation-delay: 2s;
}
.bar > span:last-child {
  animation-delay: 3s;
}
@keyframes loading {
  0%,
  100% {
    top: 0;
    left: 0;
  }
  25% {
    top: 0;
    left: 50px;
    transform: scale(2);
  }
  50% {
    top: 50px;
    left: 50px;
    transform: scale(1) rotate(90deg);
  }
  75% {
    top: 50px;
    left: 0;
    transform: scale(2) rotate(-90deg);
  }
}
