// Navbar
@import "navbar";
// Sidebar
@import "sidebar";
// Misc
@import "misc";
// Spinner/Loader
@import "loading";
// Clock
@import "clock";

#root {
  background-color: #333333;
  overflow: hidden;
}

img {
  max-width: 100%;
}
a {
  text-decoration: none;
}
.file-upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 1.5rem;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 800px;
  position: relative;
  margin-top: 2rem;
}

.file-upload h2 {
  font-size: 1.5rem;
  color: #333;
  position: relative;
  padding-right: 1rem;
  margin-bottom: 1rem;
}
.file-upload p {
  font-size: 0.875rem;
  color: #ddd;
  margin-bottom: 1rem;
}
